import { Image, LocalImage, RemoteImage } from '@grandstand/presentation-models/types/primitives/image'

export const srcForImage = (image: Image): string => {
  const isLocal = (image: any): image is LocalImage => image.type === 'local'
  const isRemote = (image: any): image is RemoteImage => image.type === 'remote'
  if (isLocal(image)) {
    return image.name // TODO(nsillik): this should point to a bundled asset
  } else if (isRemote(image)) {
    return image.link.url // TOOD(nsillik): eventually we'll need to do link param junk
  }
  return ''
}
