import { MetaDataLabel, SubheadingLabel } from '@/styles/fonts'
import { NewsCard } from '@grandstand/presentation-models/types/components/cards/news-card'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { srcForImage } from './Image'
import { textForLabel } from './Label'

const Action = styled(Link)`
  display: block;
  cursor: pointer;
`

const Content = styled.div`
  --image-size: 88px;
  display: grid;
  grid-template-columns: minmax(var(--image-size), var(--image-size)) minmax(0, 100%);
  gap: 16px;
`

const ThumbnailColumn = styled.div`
  position: relative;
  width: var(--image-size);
  height: var(--image-size);
`
const Thumbnail = styled(Image)`
  display: block;
  object-fit: cover;
`
const Labels = styled.div`
  --gap: 4px 0;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  color: var(--on-background);
`
const Meta = styled.div`
  width: 100%;
  display: flex;
  gap: 0 8px;
  text-transform: uppercase;
  color: var(--on-background-alt);
`

export const NewsCardView = ({ component }: { component: NewsCard }) => {
  const {
    content: { image, labels, text },
  } = component

  return (
    <Action href={`/article/${component.id}`}>
      <Content>
        <ThumbnailColumn>
          <Thumbnail src={srcForImage(image)} fill={true} alt="" />
        </ThumbnailColumn>
        <Labels>
          <Meta>
            {labels.map((label, index) => (
              <MetaDataLabel key={`label-${index}`} fontSize="sm" mobileFontSize="min">
                {textForLabel(label)}
              </MetaDataLabel>
            ))}
          </Meta>
          <SubheadingLabel fontSize="1">{text}</SubheadingLabel>
        </Labels>
      </Content>
    </Action>
  )
}
