import { BaseContainer } from '@/components/containers/BaseContainer'
import { TeamHubPreviewMain } from '@/components/grandstand/TeamHubPreview/TeamHubPreviewMain'
import { TeamHubPreviewNav } from '@/components/grandstand/TeamHubPreview/TeamHubPreviewNav'
import { breakpoints } from '@/styles/breakpoints'
import type { TeamHubPreview } from '@grandstand/presentation-models/types/components/teamHubPreview'
import styled from 'styled-components'
import { TeamHubPreviewProvider } from './TeamHubPreviewContext'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 40px 0;
  position: relative;
  ${breakpoints.up('lg')} {
    grid-template-columns: minmax(320px, 320px) repeat(auto-fit, minmax(320px, 1fr));
    grid-template-rows: auto;
    gap: 0 24px;
  }
`
const TeamHubPreviewStyles = styled.div`
  width: 100%;
  padding: 0;
`
const Wrapper = styled.div`
  background-color: var(--surface);
  margin: 0;
`

const Container = styled(BaseContainer)`
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  ${breakpoints.down('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
`

const NavColumn = styled.div`
  max-width: 100%;
  overflow-x: auto;
  padding: 16px 0;
  position: sticky;
  top: 0;
  ${breakpoints.up('lg')} {
    position: relative;
    padding: 48px 0;
  }
`

const MainColumn = styled.div`
  padding: 0;
  position: relative;
  ${breakpoints.up('lg')} {
    padding: 48px 0;
  }
`

export const TeamHubPreviewView = ({ component }: { component: TeamHubPreview }) => {
  return (
    <TeamHubPreviewProvider component={component}>
      <TeamHubPreviewStyles id="team-hub-preview">
        <Wrapper>
          <Container padded={true}>
            <Grid>
              <NavColumn>
                <TeamHubPreviewNav />
              </NavColumn>
              <MainColumn>
                <TeamHubPreviewMain />
              </MainColumn>
            </Grid>
          </Container>
        </Wrapper>
      </TeamHubPreviewStyles>
    </TeamHubPreviewProvider>
  )
}
