import { useColorScheme } from '@/hooks/useColorScheme'
import { breakpoints } from '@/styles/breakpoints'
import { HeadingLabel, MetaDataLabel, SubheadingLabel } from '@/styles/fonts'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { CMSRegionTeam } from '@grandstand/presentation-models/types/primitives/team'
import NextImage from 'next/image'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 0 0;
  ${breakpoints.up('md')} {
    padding: 48px 0 0;
  }
  ${breakpoints.up('lg')} {
    padding: 0;
    gap: 24px;
  }
`

const ImageCol = styled.div`
  --image-size: 48px;
  position: relative;
  display: block;
  width: var(--image-size);
  min-width: var(--image-size);
  height: var(--image-size);
  min-height: var(--image-size);

  ${breakpoints.up('md')} {
    --image-size: 88px;
  }
`

const LabelsCol = styled.div`
  width: auto;
  color: var(--on-background);
`
const TeamLabels = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const TeamHeading = styled(HeadingLabel)`
  display: block;
  width: 100%;
  /* white-space: nowrap; */
`

const LeagueLabels = styled.div`
  width: 100%;
`
const LeagueSubheading = styled(SubheadingLabel)``

const LeagueMetadata = styled(MetaDataLabel)`
  ${breakpoints.down('lg')} {
    display: none;
  }
`
type TeamHubHeaderProps = {
  team: CMSRegionTeam
  isFavorite?: boolean
}

export const TeamHubHeader = ({ team, isFavorite }: TeamHubHeaderProps) => {
  const { isDark } = useColorScheme()

  const imageSrc = isDark ? team.dark_image : team.light_image
  return (
    <Row>
      <ImageCol>
        <NextImage src={imageSrc} alt="" fill />
      </ImageCol>

      <LabelsCol>
        <LeagueLabels>
          <LeagueSubheading fontSize="2" tabletFontSize="1" mobileFontSize="0">
            {team.league.toUpperCase()} {team.DTCavailable ? '' : '• TV Provider Only'}
          </LeagueSubheading>
        </LeagueLabels>
        <TeamLabels>
          <TeamHeading fontSize="6" tabletFontSize="4" mobileFontSize="2">
            {team.team}
          </TeamHeading>
          {isFavorite ? <GrandstandIcon icon={'favorite_active'} width="16px" /> : null}
        </TeamLabels>
      </LabelsCol>
    </Row>
  )
}
