import { useUpsell } from '@/hooks/useUpsell'
import { breakpoints } from '@/styles/breakpoints'
import { MetaDataLabel, SubheadingLabel } from '@/styles/fonts'
import { ComponentVariant, ComponentVariantContext } from '@grandstand-web/bally-web-shared/src/context/VariantContexts'
import { getStaticPageUrl } from '@grandstand-web/bally-web-shared/src/utils/staticPageUtils'
import { VideoCard } from '@grandstand/presentation-models/types/components/cards/video-card'
import { NavigateAction } from '@grandstand/presentation-models/types/primitives/action'
import { VideoStatus } from '@grandstand/presentation-models/types/primitives/video'
import { default as NextImage } from 'next/image'
import Link from 'next/link'
import { MouseEvent, useContext } from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { srcForImage } from './Image'
import { textForLabel } from './Label'

type ItemGridSize = {
  width: string
  gaps: number
  items: number
}
type ItemSizeKey = 'oneThird' | 'oneQuarter' | 'oneHalf' | 'twoThirds' | 'eightyThree' | 'full'

const isVariantLg = (variant: ComponentVariant['variant']): boolean => ['lg', 'xl', 'xxl'].includes(variant)

const getImageSizesOptimizationFromVariant = (componentVariant: ComponentVariant): string | undefined => {
  const isLg = isVariantLg(componentVariant.variant)
  const xsSize = isLg ? '83.5vw' : '66vw'
  const smSize = isLg ? '66vw' : '50vw'
  const mdSize = isLg ? '50vw' : '33vw'
  const lgSize = isLg ? '33vw' : '25vw'
  const xlSize = isLg ? '33vw' : '25vw'
  const maxSize = isLg ? '540px' : '400px'
  return `(max-width: 479px) ${xsSize}, (max-width: 767px) ${smSize}, (max-width: 1279px) ${mdSize}, (max-width: 1439px) ${lgSize}, (max-width: 1632px) ${xlSize}, ${maxSize}`
}

const getCardWrapperVariables = (props: ComponentVariant): FlattenSimpleInterpolation => {
  const isLg = isVariantLg(props.variant)
  const sizes: {
    [key in ItemSizeKey]: ItemGridSize
  } = {
    oneThird: {
      width: '33.3333%',
      gaps: 2,
      items: 3,
    },
    oneQuarter: {
      width: '25%',
      gaps: 3,
      items: 4,
    },
    oneHalf: {
      width: '50%',
      gaps: 1,
      items: 2,
    },

    twoThirds: {
      width: '66.6667%',
      gaps: 0,
      items: 1,
    },
    eightyThree: {
      width: '83.5%',
      gaps: 0,
      items: 1,
    },

    full: {
      width: '100%',
      gaps: 0,
      items: 1,
    },
  }

  const lgSize = isLg ? sizes.oneThird : sizes.oneQuarter
  const mdSize = isLg ? sizes.oneHalf : sizes.oneThird
  const smSize = isLg ? sizes.twoThirds : sizes.oneHalf
  const xsSize = isLg ? sizes.eightyThree : sizes.twoThirds
  const getGapDiff = (size: ItemGridSize) => `var(--row-gap, 24px) / ${size.items} * ${size.gaps}`
  return css`
    --card-wrapper-xs-width: calc(${xsSize.width} - ${getGapDiff(xsSize)});
    --card-wrapper-sm-width: calc(${smSize.width} - ${getGapDiff(smSize)});
    --card-wrapper-md-width: calc(${mdSize.width} - ${getGapDiff(mdSize)});
    --card-wrapper-lg-width: calc(${lgSize.width} - ${getGapDiff(lgSize)});
    --card-wrapper-xl-width: var(--card-wrapper-lg-width);
  `
}
const CardWrapper = styled.div<ComponentVariant>`
  ${getCardWrapperVariables}
  display: block;
  min-width: var(--card-wrapper-xs-width);
  width: var(--card-wrapper-xs-width);
  max-width: var(--card-wrapper-xs-width);
  @media (min-width: 480px) {
    min-width: var(--card-wrapper-sm-width);
    width: var(--card-wrapper-sm-width);
    max-width: var(--card-wrapper-sm-width);
  }
  @media (min-width: 768px) {
    min-width: var(--card-wrapper-md-width);
    width: var(--card-wrapper-md-width);
    max-width: var(--card-wrapper-md-width);
  }
  @media (min-width: 1280px) {
    min-width: var(--card-wrapper-lg-width);
    width: var(--card-wrapper-lg-width);
    max-width: var(--card-wrapper-lg-width);
  }
  @media (min-width: 1440px) {
    min-width: var(--card-wrapper-xl-width);
    width: var(--card-wrapper-xl-width);
    max-width: var(--card-wrapper-xl-width);
  }
`

const CardAction = styled(Link)<{ $isUpcoming: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: ${(props) => (props.$isUpcoming ? 'unset' : 'pointer')};
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
const InfoMeta = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
`

const ImageWrapper = styled.div<ComponentVariant>`
  --wide-aspect-ratio: 16/9;

  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => `var(--${props.size}-aspect-ratio)`};
  overflow: hidden;
  border-radius: 4px;
`

const OverlayLabelWrapper = styled.div`
  position: absolute;
  bottom: 13px;
  left: 13px;
`

const InfoStatusLabel = styled(MetaDataLabel)`
  text-transform: uppercase;
  width: max-content;
  padding: 5px 8px;
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.2);
`

const InfoMetaLabel = styled(MetaDataLabel)`
  display: inline-block;
  text-transform: uppercase;
  color: var(--on-background-alt);
`

const TitleLabelHero = styled(SubheadingLabel)`
  color: var(--on-background);
  ${breakpoints.up('md')} {
    color: var(--white);
  }
`

const TitleLabel = styled(SubheadingLabel)`
  color: var(--on-background);
`
export const VideoCardView = ({ component }: { component: VideoCard }) => {
  const {
    id,
    meta,
    content: { image, title_label, caption_labels, status_label, video, action, should_overlay_text },
  } = component

  // Grab the neighborindIds of videos in rail for live-events/vod-page
  const parsedUrl = new URL(action.link.url)
  const neighboringIds = parsedUrl.searchParams.get('ids')
  const staticPageUrl = getStaticPageUrl(action as NavigateAction, video?.id as string, neighboringIds as string)

  const {
    upsell: { contentUrl, isEntitled },
    goToEntitled,
  } = useUpsell({ contentUrl: staticPageUrl, meta })

  // variant

  const { variant, size } = useContext(ComponentVariantContext)

  // image sizes optimization based on variant
  const imageSizesOptimization = getImageSizesOptimizationFromVariant({
    variant,
    size,
  })

  // handlers
  const handleActionOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    goToEntitled()
  }
  return (
    <CardWrapper size={size} variant={variant} id={id}>
      <CardAction
        href={isEntitled ? contentUrl : `#${id}`}
        $isUpcoming={video?.status === VideoStatus.upcoming}
        onClick={handleActionOnClick}
      >
        <ImageWrapper size={size} variant={variant}>
          <NextImage src={srcForImage(image)} alt="" fill sizes={imageSizesOptimization} />
          {should_overlay_text && (
            <OverlayLabelWrapper>
              <InfoWrapper>
                {status_label && status_label.type === 'text' && (
                  <InfoStatusLabel fontSize="sm" tabletFontSize="sm" mobileFontSize="xs">
                    {status_label.value}
                  </InfoStatusLabel>
                )}
                {/* <InfoMeta>
                  {caption_labels.map((label: any) => {
                    return (
                      <InfoMetaLabel fontSize="sm" tabletFontSize="sm" mobileFontSize="xs" key={textForLabel(label)}>
                        {textForLabel(label)}
                      </InfoMetaLabel>
                    )
                  })}
                </InfoMeta> */}
                {caption_labels.length === 1 && (
                  <TitleLabelHero fontSize="2" tabletFontSize="1" mobileFontSize="0">
                    {textForLabel(title_label)}
                  </TitleLabelHero>
                )}
                {caption_labels.length !== 1 && (
                  <TitleLabel fontSize="2" tabletFontSize="1" mobileFontSize="0">
                    {textForLabel(title_label)}
                  </TitleLabel>
                )}
              </InfoWrapper>
            </OverlayLabelWrapper>
          )}
        </ImageWrapper>
        {!should_overlay_text && (
          <InfoWrapper>
            <InfoMeta>
              {caption_labels.map((label: any) => {
                return (
                  <InfoMetaLabel fontSize="sm" tabletFontSize="sm" mobileFontSize="xs" key={textForLabel(label)}>
                    {textForLabel(label)}
                  </InfoMetaLabel>
                )
              })}
            </InfoMeta>
            {caption_labels.length === 1 && (
              <TitleLabelHero fontSize="2" tabletFontSize="1" mobileFontSize="0">
                {textForLabel(title_label)}
              </TitleLabelHero>
            )}
            {caption_labels.length !== 1 && (
              <TitleLabel fontSize="2" tabletFontSize="1" mobileFontSize="0">
                {textForLabel(title_label)}
              </TitleLabel>
            )}
          </InfoWrapper>
        )}
      </CardAction>
    </CardWrapper>
  )
}
