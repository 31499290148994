import { PrimaryLabel } from '@/styles/fonts'
import { Grid } from '@grandstand/presentation-models/types/components/grid'
import styled from 'styled-components'
import { BaseContainer } from '../containers/BaseContainer'
import { ComponentRenderer } from './ComponentRenderer'
import { textForLabel } from './Label'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled(BaseContainer)`
  padding-bottom: 16px;
`
const Labels = styled.div`
  padding: 0 0 16px;
`
const TitleLabel = styled(PrimaryLabel)`
  color: var(--swimlane-button-icon-color);
  text-transform: uppercase;
`

const ItemsGrid = styled.div`
  width: 100%;
  max-width: 100%;
  /* layout styles */
  --columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  gap: 24px;

  @media (min-width: 768px) {
    --columns: 2;
  }
  @media (min-width: 1280px) {
    --columns: 4;
  }
`
export const GridView = ({ component }: { component: Grid }) => {
  const { items } = component
  return (
    <Wrapper>
      <Container padded={true}>
        <Labels>
          <TitleLabel fontSize="lg" tabletFontSize="sm" mobileFontSize="sm">
            {textForLabel(component.content.header?.label)}
          </TitleLabel>
        </Labels>
        <ItemsGrid>
          <>
            {items.map((item) => {
              return <ComponentRenderer component={item} key={item.id} />
            })}
          </>
        </ItemsGrid>
      </Container>
    </Wrapper>
  )
}
