import { BaseContainer } from '@/components/containers/BaseContainer'
import { PrimaryLabel } from '@/styles/fonts'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { ComponentVariant, ComponentVariantContext } from '@grandstand-web/bally-web-shared/src/context/VariantContexts'
import { Rail } from '@grandstand/presentation-models/types/components/rail'
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { ComponentRenderer } from './ComponentRenderer'
import { textForLabel } from './Label'

type RailVariant = ComponentVariant['variant']

interface RailBreakpoints {
  controlsVisible: number
  [key: string]: number
}
interface ControlsState {
  scrollLeft: number
  scrollWidth: number
  offsetWidth: number
  scrollDistance: number
  canScroll: boolean
  canScrollRTL: boolean
  canScrollLTR: boolean
  breakpoints: RailBreakpoints
}
// Gap between items in rail, on desktop
const ITEMS_ROW_GAP = 24

const railContainerStyles = css`
  width: 100%;
  max-width: 100%;
`

// Styling for the rail wrapper
const RailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${railContainerStyles}
`

const ItemsWrapper = styled(BaseContainer)`
  ${railContainerStyles}
  padding: 0;
  @media (min-width: 1280px) {
    padding: 0 48px;
  }
`

const ItemsContainer = styled(ItemsWrapper)`
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
`

const ItemsRow = styled(ItemsWrapper)`
  --row-gap: ${`${ITEMS_ROW_GAP}px`};
  ${railContainerStyles}
  position: relative;
  display: flex;
  gap: var(--row-gap);
  padding: 0 16px;
  overflow: hidden;

  @media (min-width: 480px) {
    padding: 0 24px;
  }
  @media (min-width: 1280px) {
    padding: 0;
  }

  @media (max-width: ${({ breakpoints }: { breakpoints: RailBreakpoints }) => breakpoints.controlsVisible - 1}px) {
    overflow-x: auto !important;
    padding-bottom: 8px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const HeaderContainer = styled(BaseContainer)`
  ${railContainerStyles}
  padding-bottom: 16px;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RailControls = styled.div<{ breakpoints: RailBreakpoints }>`
  display: none;
  // controls visible
  @media (min-width: ${({ breakpoints }) => breakpoints.controlsVisible}px) {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`

const RailButton = styled.button`
  --swimlane-button-icon-color: var(--button);
  --swimlane-button-border-color: var(--button-alt);
  --swimlane-button-transition: color 0.1s linear, border-color 0.1s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-within {
    --swimlane-button-border-color: var(--button);
  }

  &:disabled {
    --swimlane-button-icon-color: var(--button-alt);
    --swimlane-button-border-color: var(--button-alt);
    pointer-events: none;
  }

  .decoration {
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 50%;
    border: 1px solid var(--swimlane-button-border-color);
    transition: var(--swimlane-button-transition);
    &,
    div,
    svg {
      color: var(--swimlane-button-icon-color);
    }
  }

  svg path {
    fill: currentColor;
  }

  &,
  .decoration {
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      width: 100%;
      height: 100%;
    }
    &,
    div,
    svg,
    svg path {
      transition: var(--swimlane-button-transition);
    }
  }
`

const RailLabel = styled(PrimaryLabel)`
  color: var(--swimlane-button-icon-color);
  text-transform: uppercase;
`

const useRailControls = (variant: RailVariant = 'md') => {
  const itemsRowRef = useRef<HTMLDivElement>(null)

  const [controls, setControls] = useState<ControlsState>({
    scrollLeft: 0,
    scrollWidth: 0,
    offsetWidth: 0,
    scrollDistance: 0,
    canScroll: false,
    canScrollRTL: false,
    canScrollLTR: false,
    breakpoints: {
      controlsVisible: 480,
    },
  })

  const scrollItems = (rtl: boolean) => {
    setControls((prev) => {
      const { scrollLeft, scrollWidth, scrollDistance } = prev

      const nextScrollLeft = rtl
        ? Math.max(0, scrollLeft - scrollDistance) // scroll items into visible rail from right to left
        : Math.min(scrollLeft + scrollDistance, scrollWidth - scrollDistance + ITEMS_ROW_GAP) // from left to right, but never scroll more than max possible scrollLeft

      return {
        ...prev,
        scrollLeft: nextScrollLeft,
        canScrollRTL: nextScrollLeft > 0,
        canScrollLTR: nextScrollLeft < scrollWidth - scrollDistance,
      }
    })
  }

  useLayoutEffect(() => {
    if (itemsRowRef.current) {
      const container = itemsRowRef.current
      const { scrollLeft } = controls

      container.scrollTo({
        top: 0,
        left: scrollLeft,
        behavior: 'smooth',
      })
    }
  }, [controls, controls.scrollLeft])

  useEffect(() => {
    const updateScrollState = () => {
      const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0
      const windowWidth = window.innerWidth
      const breakpoints: RailBreakpoints = {
        controlsVisible: isTouch ? window.innerWidth + 1 : 480,
      }
      if (!isTouch) {
        switch (variant) {
          case 'lg':
          case 'xl':
            breakpoints.controlsVisible = 768
            break
        }
      }

      const container = itemsRowRef.current

      if (container) {
        const { scrollWidth, offsetWidth } = container
        const scrollLeft = 0

        const controlsVisible = windowWidth >= breakpoints.controlsVisible
        let scrollDistance = offsetWidth + ITEMS_ROW_GAP
        if (windowWidth < 1280) {
          scrollDistance = offsetWidth - ITEMS_ROW_GAP
        }

        setControls((prev) => ({
          ...prev,
          scrollLeft: 0,
          offsetWidth,
          scrollWidth,
          scrollDistance,
          canScroll: offsetWidth < scrollWidth,
          canScrollRTL: scrollLeft > 0,
          canScrollLTR: scrollLeft < scrollWidth - scrollDistance,
          breakpoints,
        }))
      }
    }

    updateScrollState()

    const handleResize = () => {
      updateScrollState()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [variant])

  return {
    itemsRowRef,
    controls,
    setControls,
    scrollItems,
  }
}
export const RailView = ({ component }: { component: Rail }) => {
  const baseVariant = useContext(ComponentVariantContext)
  const {
    items,
    meta: {
      items_info: { variant: itemsVariant },
    },
  } = component

  const variant: RailVariant = itemsVariant ?? baseVariant.variant
  const {
    itemsRowRef,
    controls,
    controls: { breakpoints },
    scrollItems,
  } = useRailControls(variant)
  return (
    <BaseContainer padded={false}>
      <RailWrapper>
        <HeaderContainer padded={true}>
          <HeaderRow>
            <div>
              <RailLabel fontSize="lg" tabletFontSize="sm" mobileFontSize="sm">
                {textForLabel(component.content.header?.label)}
              </RailLabel>
            </div>
            {controls.canScroll && (
              <RailControls breakpoints={breakpoints}>
                <RailButton type="button" onClick={() => scrollItems(true)} disabled={!controls.canScrollRTL}>
                  <div className="decoration">
                    <GrandstandIcon width="16px" icon="arrow_left" />
                  </div>
                </RailButton>

                <RailButton type="button" onClick={() => scrollItems(false)} disabled={!controls.canScrollLTR}>
                  <div className="decoration">
                    <GrandstandIcon width="16px" icon="arrow_right" />
                  </div>
                </RailButton>
              </RailControls>
            )}
          </HeaderRow>
        </HeaderContainer>
        <ComponentVariantContext.Provider value={{ variant: variant ?? baseVariant.variant, size: 'wide' }}>
          <ItemsWrapper padded={true}>
            <ItemsContainer>
              <ItemsRow ref={itemsRowRef} breakpoints={breakpoints}>
                {items &&
                  items.map((item) => {
                    return <ComponentRenderer component={item} key={item.id}></ComponentRenderer>
                  })}
              </ItemsRow>
            </ItemsContainer>
          </ItemsWrapper>
        </ComponentVariantContext.Provider>
      </RailWrapper>
    </BaseContainer>
  )
}
