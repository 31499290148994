import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { MetaEntitlementType } from '@grandstand/presentation-models/types/components/_abstract'
import { AuthProfileOutput, UserEntitlement } from '@grandstand/presentation-models/types/responses/profile'
import { useContext, useEffect, useState } from 'react'

export type EntitlementMeta = {
  local_network_ids?: string[]
  entitlement_type?: MetaEntitlementType
}

export enum UpsellMode {
  entitled = 'entitled',
  both = 'both',
  mvpd = 'mvpd'
}

export type UpsellState = {
  isEntitled: boolean | undefined
  isSeasonallyPaused: boolean
  mode: UpsellMode
  reason: string
  contentUrl: string
  meta: EntitlementMeta | undefined
}

export type UpsellStore = {
  upsell: UpsellState
}
export type UpsellProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
}
export const useUpsellLogic = ({ contentUrl = '/', meta }: UpsellProps) => {
  const { isDTC, isMVPD, isLoggedIn, currentUser } = useContext(UserServiceContext)
  const [upsell, setUpsell] = useState<UpsellState>({
    contentUrl,
    meta,
    isEntitled: true,
    isSeasonallyPaused: false,
    reason: 'Initial upsell upsell',
    mode: UpsellMode.entitled
  })
  useEffect(() => {
    const next = getUpsellState({ contentUrl, meta, currentUser })
    setUpsell(next)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser, meta?.local_network_ids, meta?.entitlement_type, isLoggedIn, isDTC, isMVPD, meta])

  return {
    upsell
  }
}
type GetUpsellStateProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
  // userService: UserService
  currentUser?: AuthProfileOutput
}
export const getUpsellState = ({ contentUrl, meta, currentUser }: GetUpsellStateProps): UpsellState => {
  // convert the useEffect above into a functon that returns UpsellState
  const getState = (): UpsellState => {
    const contentIds: string[] = meta?.local_network_ids ?? []
    const contentType: MetaEntitlementType | undefined = meta?.entitlement_type
    const mode = contentType === 'mvpd' ? UpsellMode.mvpd : UpsellMode.both
    const createState = (isEntitled: boolean, reason: string, isSeasonallyPaused: boolean = false): UpsellState => {
      const next: UpsellState = {
        meta: meta,
        contentUrl: contentUrl,
        isEntitled,
        isSeasonallyPaused,
        mode,
        reason
      }
      return next
    }

    if (currentUser === undefined) {
      return createState(false, 'Current user is not entitled because currentUser is missing')
    }
    const userEntitlements: UserEntitlement[] = currentUser.profile.entitlements
    const userPausedEntitlements: UserEntitlement[] = currentUser.profile.paused_entitlements || []

    if (meta === undefined) {
      return createState(true, 'User is entitled because content meta is missing')
    }

    if (contentIds.length === 0) {
      return createState(true, 'User is entitled because content meta has no local network ids')
    }

    if (contentType === 'free') {
      return createState(true, 'User is entitled because content entitlement type is Free')
    }

    const getContentIsInUserRegion = (entitlements: UserEntitlement[]): boolean => {
      if (contentIds?.length === 0) {
        // Content is free for all
        return true
      }
      if (entitlements?.length <= 0) {
        return false
      }
      const results = entitlements.filter((value) => {
        const typeMatched = contentType === 'mvpd' ? value.type === 'mvpd' : true
        if (!typeMatched) {
          return false
        }
        return entitlements.some((value) => {
          const areas = value.local_network_ids
          return areas.some((area) => contentIds.includes(area))
        })
      })
      return results.length > 0
    }
    const entitlementsString = JSON.stringify(userEntitlements)
    const pausedEntitlementsString = JSON.stringify(userPausedEntitlements)
    if (getContentIsInUserRegion(userEntitlements)) {
      return createState(true, `User entitled to ${contentType} content with entitlements = ${entitlementsString}`)
    }
    if (getContentIsInUserRegion(userPausedEntitlements)) {
      return createState(
        false,
        `User not entitled to ${contentType} content. Entitlements are paused: ${pausedEntitlementsString}`,
        true
      )
    }

    return createState(false, `User not entitled to ${contentType} content with entitlements = ${entitlementsString}`)
  }

  const upsellState = getState()
  return upsellState
}
