import { Tab, getTabs } from '@/components/teams'
import { useLocalStorage } from '@grandstand-web/bally-web-shared/src/hooks/useLocalStorage'
import type { TeamHubPreview } from '@grandstand/presentation-models/types/components/teamHubPreview'
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useEffect, useMemo } from 'react'

export type TeamHubPreviewContextType = {
  tabs: Tab[]
  activeTab: Tab | undefined
  setActiveTab: Dispatch<SetStateAction<Tab | undefined>>
}

const initialContextvalue: TeamHubPreviewContextType = {
  tabs: [],
  activeTab: undefined,
  setActiveTab: (value: SetStateAction<Tab | undefined>) => {},
}

export const TeamHubPreviewContext = createContext<TeamHubPreviewContextType>(initialContextvalue)

type TeamHubPreviewProviderProps = {
  component: TeamHubPreview
}
export const TeamHubPreviewProvider = ({ children, component }: PropsWithChildren<TeamHubPreviewProviderProps>) => {
  const tabs = useMemo<Tab[]>(() => getTabs(component), [component])
  const [activeTab, setActiveTab] = useLocalStorage<Tab | undefined>(
    'teamHubPreview.activeTab',
    tabs?.length > 0 ? tabs[0] : undefined
  )
  useEffect(() => {
    const hasTabs = tabs?.length > 0
    const tabNotSelected = hasTabs && activeTab === undefined
    const tabNotFound = hasTabs && activeTab && tabs.findIndex((tabItem) => tabItem.id === activeTab.id) === -1
    if (tabNotSelected || tabNotFound) {
      setActiveTab(tabs[0])
    }
    return () => {}
  }, [tabs, activeTab, setActiveTab])
  return (
    <TeamHubPreviewContext.Provider value={{ tabs, activeTab, setActiveTab }}>
      {children}
    </TeamHubPreviewContext.Provider>
  )
}

export const useTeamHubPreview = () => {
  return useContext(TeamHubPreviewContext)
}
