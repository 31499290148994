import { WatchPage } from '@grandstand/presentation-models/types/components/page'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ComponentRenderer } from './ComponentRenderer'

interface WatchPageProps {
  component: WatchPage
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
`

export const WatchPageView = ({ component }: WatchPageProps) => {
  const router = useRouter()
  return (
    <PageWrapper>
      {component.items.map((item) => {
        return <ComponentRenderer component={item} key={item.id}></ComponentRenderer>
      })}
    </PageWrapper>
  )
}
