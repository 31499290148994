import { NewsCard } from '@grandstand/presentation-models/types/components/cards/news-card'
import { VideoCard } from '@grandstand/presentation-models/types/components/cards/video-card'
import type {
  TeamHubPreview,
  TeamHubPreviewContent,
  TeamHubPreviewTabContent,
} from '@grandstand/presentation-models/types/components/teamHubPreview'
import { Ref } from '@grandstand/presentation-models/types/primitives/ref'
import { CMSRegionTeam } from '@grandstand/presentation-models/types/primitives/team'

export { TeamHubPreview, TeamHubPreviewContent, TeamHubPreviewTabContent }

export type Tab = {
  id: string
  team: CMSRegionTeam
  hasNewsCards: boolean
  hasVideoCards: boolean
  videoCards?: VideoCard[]
  newsCards?: NewsCard[]
}

export const getTab = (content: TeamHubPreviewTabContent, items: Ref<VideoCard | NewsCard>[] = []): Tab => {
  const { articles, videos, team } = content
  const id = `team-tab-${team.slug}`
  const hasItems = items?.length > 0
  const hasVideoCards = hasItems && videos?.length > 0
  const hasNewsCards = hasItems && articles?.length > 0
  const getItemCards = <T>(ids: string[]): T[] => {
    if (!ids.length || !items.length) return [] as T[]
    return ids.reduce((acc: T[], itemId) => {
      const index = items.findIndex((item) => item.id === itemId)
      if (index > -1) {
        const item = items[index] as unknown as T
        acc.push(item)
      }
      return acc
    }, [] as T[]) as T[]
  }
  const newsCards: NewsCard[] = getItemCards<NewsCard>(articles)
  const videoCards: VideoCard[] = getItemCards<VideoCard>(videos)

  return {
    id,
    team: content.team,
    hasNewsCards,
    newsCards: hasNewsCards ? newsCards : undefined,
    hasVideoCards,
    videoCards: hasVideoCards ? videoCards : undefined,
  }
}
export const getTabs = (component: TeamHubPreview): Tab[] => {
  const {
    items,
    content: { teamContent },
  } = component
  if (teamContent.length > 0) {
    return teamContent.map((item: TeamHubPreviewTabContent) => getTab(item, items) as Tab) as Tab[]
  }
  return [] as Tab[]
}
